.active {
  display: flex;
  align-items: center;
  color: #0084e2;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  padding: 0 12px;
  box-sizing: border-box;

  > div {
    background-color: #e5f3fc;
  }
  h5 {
    color: #0084e2 !important;
  }
}

.sidebar-menu {
  width: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  justify-content: space-around;
  padding: 0;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: all 300ms;
  gap: 3px;

  > div {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    transition: all 500ms;
  }

  h5 {
    display: block;
    position: relative;
    font-size: 14px;
    width: 110px;
    text-align: center;
    color: gray;
    margin-top: 2px;
    line-height: 22px !important;
  }
}

.sidebar-menu:hover > div {
  background-color: #e5f3fc6e;
}

.toggleLogoContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.profile {
  display: flex;
  align-items: center;
}

.profile_user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 10px;
  padding: 10px;
}

.profile_user p {
  margin: 0 5px 0 0;
}

.pop_up_exit_system {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.pop_up_exit {
  width: 28px;
  height: 29px;
  border: 1px solid #d7ddea;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pop_up_exit_refresh_img {
  animation-name: refreshing;
  animation-duration: 600ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.edit-password-box {
  width: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 20px;
  gap: 20px;
}

.path_to_back {
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-bottom: 15px;
  width: 400px;
  gap: 10px;
}

.close-icon {
  cursor: pointer;
  font-size: 20px;
  padding-right: 5px;
}

.close-icon:hover {
  color: #878e9c;
}

.path_to_edit {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}

.path_to_edit > .icon_back {
  width: 30px;
  height: 30px;
}

.icon_back {
  width: 30px;
  height: 30px;
  padding: 10px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d7ddea;
  border-radius: 50%;
  color: #1c274c;
  cursor: pointer;
}

.box {
  margin: 14px 0 0;
  padding: 22px;
  background-color: #ffffff;
  border: 1px solid #d7ddea;
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
}

.backgroundColorWhite {
  background-color: #ffffff;
  border-radius: 12px !important;
}

.numberBox {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: #e6eaf3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #132532;
}

.indications_box-gas_bottom_true,
.total-accepted__true {
  padding: 15px 20px;
  border-radius: 12px;
  background-color: #e5f3fc;
}

.indications_box-gas_bottom_false,
.total-accepted__false {
  padding: 15px 20px;
  border-radius: 12px;
  background-color: #ffeeef;
}

.text_true {
  color: #1976d2;
}

.text_false {
  color: red;
}

.error {
  margin-top: 5px;
  color: #ff5963;
  font-weight: 400;
  font-size: 11px;
  padding-left: 5px;
  font-style: italic;
}

.page_null {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(215, 221, 234);
  border-radius: 12px;
  background: white;
  margin-top: 14px;
}

.header_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.index_search {
  width: 100%;
  height: 55px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid #d7ddea;
  background-color: white;
  color: #1c274c;
  background-color: #ffffff;
  overflow: hidden;
}

.index_search fieldset {
  border: none !important;
}

.index_search .fa-search {
  margin-right: 9px;
  margin-left: 5px;
  color: #d7ddea;
}

.index_search input {
  width: 220px;
  height: 30px;
  border: none;
}

input::placeholder {
  font-size: 15px !important;
}

input::-webkit-input-placeholder {
  font-size: 15px !important;
}
input::-moz-placeholder {
  font-size: 15px !important;
}
input:-ms-input-placeholder {
  font-size: 15px !important;
}
input:-moz-placeholder {
  font-size: 15px !important;
}

.inputInTable {
  border: 1px solid #d7ddea;
  padding: 0 12px;
  width: 100%;
  border-radius: 6px;
  height: 40px;
}

.inputTariff {
  border: 1px solid #d7ddea;
  padding: 0 12px;
  width: 100%;
  border-radius: 12px;
  height: 50px;
}

.clear_button {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  border: none;
  cursor: pointer;
  color: #d7ddea;
  font-size: 16px;
  background-color: #fff;
}

.clear_button:hover {
  width: 20px;
  height: 20px;
  color: #9aa1ae;
}

.selector {
  width: 300px;
}

.cardContainer {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 398px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #d7ddea;
  padding: 40px 20px 20px;
  gap: 30px;
}

.tariffLabel {
  position: absolute;
  background-color: #21e1b3;
  border-radius: 12px;
  top: -16px;
  left: 16px;
  display: flex;
  padding: 4px 20px 5px;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.date_picker {
  height: 55px;
  display: flex;
  align-items: center;
  border: 1px solid #d7ddea;
  border-radius: 12px;
  padding: 0 18px;
}

.date_picker_input {
  border: none;
  font-size: 15px !important;
  color: #132532 !important;
  background-color: #fff !important;
}

.excel-icon {
  font-size: 20px;
  color: #878e9c;
  cursor: pointer;
  margin-right: 10px;
}

.company-name-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(234, 237, 243);
  border-radius: 12px;
  padding: 30px;
}

.company-name-logo-img {
  width: 100%;
  object-fit: cover;
}

.selected-logo {
  width: 200px;
  height: 200px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: hidden;
}

.btn--container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

.img--container {
  display: flex;
  flex-direction: row;
  width: 20%;
  margin-top: 14px;
}

.img--box {
  object-fit: cover;
  width: calc(100% - 60px);
  border-radius: 12px;
  margin-right: 6px;
}

.img--zoom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 700px;
  height: 700px;
  overflow: auto;
  object-fit: contain;
  background-color: white;
  border-radius: 12px;
}

.btn--box {
  display: flex;
  gap: 14px;
}

.btn_container {
  display: flex;
  gap: 10px;
}

.image-container {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
}

.image-item {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: calc(20% - 0px);
  padding: 14px;
  gap: 16px;
  border: 1px solid #d7ddea;
  border-radius: 12px;
}

.img_uploading {
  object-fit: cover;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
}

.image-item__btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
}

.icon {
  color: #7e8299;
}

.icon:hover {
  color: #0084e2;
}

.custom-file-input {
  position: relative;
  display: flex;
  cursor: pointer;
}

.custom-file-input input[type='file'] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.custom-file-input p,
.custom-file-input > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  padding: 16px 20px;
  border: 1px solid #0084e2;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  color: #0084e2;
  cursor: pointer;
}

.custom-file-input p span {
  margin-left: 10px;
}

.login_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../public/img/autorization.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login_container {
  width: 400px;
  height: 435.2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 38px;
  padding: 5px;
  display: inline-block;
  margin-top: -4px;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.logoLogin {
  width: 100%;
  height: 43.2px;
}

.image-upload-container {
  display: block;
  position: relative;
  width: 100%;
  height: 150px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='gray' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 18px;
  overflow: hidden;
  margin-top: 18px;
}

.right-side-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px !important;
}

.right-side-container-block {
  display: flex;
  flex-flow: row wrap;
  margin: 0 !important;
  gap: 10px;

  padding: 14px;
  border-radius: 12px;
  box-shadow:
    0 1px 2px rgba(62, 62, 62, 0.28),
    0 1px 2px rgba(62, 62, 62, 0.28);
  transition:
    transform 0.35s,
    box-shadow 0.35s;

  span {
    opacity: 0.8;
  }
}

.label-filter {
  width: 100%;
  font-size: 15px;
  display: block;
  font-weight: 600;
}

.action-container-block {
  width: 5%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  cursor: pointer;
}

.action-container-block-disabled {
  width: 5%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  cursor: not-allowed;
}

.elevation-filter-wrapper:hover {
  box-shadow: '0 1px 2px rgba(62, 62, 62, 0.24), 0 1px 2px rgba(62, 62, 62, 0.24)';
}

.preloader-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.rotating-circle {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top: 3px solid #b3e5fc;
  animation: rotate 2.5s linear infinite;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #90caf9;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: float 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  transform: rotate(calc(90deg * var(--i))) translate(50px);
}
.dot:nth-child(2) {
  transform: rotate(calc(90deg * var(--i))) translate(50px);
}
.dot:nth-child(3) {
  transform: rotate(calc(90deg * var(--i))) translate(50px);
}
.dot:nth-child(4) {
  transform: rotate(calc(90deg * var(--i))) translate(50px);
}

.loading-text {
  display: block;
  margin-top: 20px;
  font-size: 18px;
  color: #6c757d;
  letter-spacing: 1px;
  text-align: center;
  line-height: 24px;
  animation: text-appear 1.5s infinite alternate;
}

.callFilterButton .callFilterButton__clear {
  transition: all 500ms;
  transform: translateX(48px);
  cursor: pointer;
}

.callFilterButton:hover .callFilterButton__clear {
  transition: all 800ms;
  transform: translateX(0px);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@keyframes text-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes refreshing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 1050px) and (max-width: 1400px) {
  .filter {
    width: 210px;
    margin: 0;
  }
}

// ----------------------------------------------------------------
// технический долг - вынести в styled components
// ----------------------------------------------------------------

.custom-select {
  &__control {
    // ----------------------------------------------------------------
    // height: 50px !important;
    // ----------------------------------------------------------------
    background-color: #fff !important;
    border: 1px solid #d7ddea !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    font-size: 15px;

    &--menu-is-open {
      border-color: #d7ddea !important;
    }
  }

  &__indicators {
    cursor: pointer !important;
    color: #878e9c !important;

    &:hover {
      color: #132532 !important;
    }
  }

  &__multi-value {
    width: 90px !important;
    background-color: #eaedf3 !important;
    border-radius: 5px !important;
    cursor: default !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    font-size: 13px !important;
    margin-right: 5px !important;
    height: 35px !important;
  }

  &__multi-value__label {
    cursor: default !important;
  }

  &__multi-value__remove {
    cursor: pointer !important;
    height: 20px !important;
    &:hover {
      background-color: #878e9c !important;
      color: #132532 !important;
    }
  }

  &__menu {
    background-color: #fff !important;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    font-size: 15px;
  }

  &__option {
    cursor: pointer !important;

    &--is--focused {
      background-color: #e5f3fc !important;
    }

    &--is--selected {
      background-color: #0084e2 !important;
    }
  }
}

::-webkit-scrollbar {
  width: 9px !important;
  height: 9px !important;
  background-color: #f9f9fd;
}

::-webkit-scrollbar-horizontal {
  height: 9px;
  background-color: #f9f9fd;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  background-color: #9aa1ae !important;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2) !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px !important;
  background-color: #f9f9fd !important;
}

.MuiDataGrid-scrollbarContent .MuiFormControlLabel-label {
  font-family: 'Wix Madefor Display', sans-serif !important;
  font-size: 15px !important;
}

.MuiDialog-paper {
  max-width: 100vw !important;
}

.imageSliderPoint,
.imageSliderPoint__slider {
  transition: all 300ms;
}

.MuiDataGrid-cell {
  p {
    display: block;
  }

  display: flex;
  flex-flow: row;
  align-items: center;
}

.MuiDataGrid-cell--textLeft:first-child,
.MuiDataGrid-columnHeader--sortable:first-child {
  padding-left: 20px !important;
}

.custom-select__value-container--is-multi > div {
  width: 250px !important;
}

.custom-select__multi-value {
  width: calc(100% - 8px) !important;
}

.MuiTabs-vertical,
.MuiTabs-hideScrollbar {
  overflow: visible !important;
}

.MuiDataGrid-scrollbar--vertical {
  display: none;
}

.MuiDataGrid-scrollbarFiller--pinnedRight {
  background-color: #f9f9fd !important;
}

.MuiDataGrid-scrollbarFiller {
  border: none !important;
}

.MuiAlert-message {
  font-size: 15px !important;
  font-weight: 400;
}

.MuiFormControl-root .MuiInputLabel-formControl {
  font-family: 'Wix Madefor Display', sans-serif !important;
  transform: translate(14px, -10px) scale(1);
  font-size: 14px;
}

.MuiPickersYear-yearButton,
.MuiPickersMonth-monthButton {
  font-weight: 400 !important;
}

.MuiTooltip {
  &-tooltip {
    font-family: 'Wix Madefor Display', sans-serif !important;
    border-radius: 12px 12px 0 12px !important;
    background-color: #132532 !important;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2) !important;
    padding: 10px 16px !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-size: 13px !important;
  }
}

.css-1ewo537 {
  border-radius: 12px !important;
}

.custom-select__value-container--is-multi {
  z-index: 10;
}

.MuiTooltip {
  &-tooltip {
    font-family: 'Wix Madefor Display', sans-serif !important;
    border-radius: 12px 12px 12px 12px !important;
    background-color: #132532 !important;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2) !important;
    padding: 10px 16px !important;
    line-height: 22px !important;
    font-weight: 400 !important;
    font-size: 13px !important;
  }
}

.MuiDrawer-modal {
  z-index: 2000;
}

.MuiButtonBase-root {
  font-weight: 500 !important;
  letter-spacing: 0.3px;
}

.MuiTypography-root,
.MuiListSubheader-root {
  font-family: 'Wix Madefor Display', sans-serif !important;
}

.MuiListSubheader-root {
  font-size: 14px;
}

.MuiListItemText-root .MuiTypography-root {
  font-size: 15px;
}

.MuiDataGrid-menuList li:nth-child(7),
.MuiDataGrid-menuList .MuiDivider-root:nth-of-type(2) {
  display: none;
}

.MuiDataGrid-columnsManagementFooter button {
  font-size: 15px !important;
}

.MuiDataGrid-columnsManagementSearchInput {
  display: none !important;
}

.MuiDataGrid-columnsManagementHeader {
  padding: 6px 24px !important;
}

.MuiButton-root:disabled {
  opacity: 0.66;
  color: rgb(202, 202, 202);
}

.MuiPickersToolbar-root {
  display: none;
}

.css-1y8nxit-MuiDrawer-docked .MuiDrawer-paper,
.css-1y8nxit-MuiDrawer-docked {
  width: 238px;
}

.css-1q4tmco,
.css-1dkz5qc {
  border: 1px solid rgb(215, 221, 234);
}

.css-qtxpdb-placeholder {
  margin-bottom: -2px;
}

.css-12i5cdh-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #1976d2;
}

.css-12i5cdh-MuiButtonBase-root-MuiRadio-root.Mui-checked:before {
  background: none;
}

.page_null .MuiTypography-root {
  font-size: 18.8px;
}

.sidebar-menu > h5,
.MuiButtonBase-root > h5 {
  font-weight: 400 !important;
}

.custom-select__value-container--is-multi {
  margin-top: 12px;
  margin-bottom: 12px;
}

.actions-header,
.actions-cell {
  background-color: #f9f9fd !important;
}

.suggestion-item:hover {
  background-color: #deebff;
}

.hidden-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.MuiDataGrid-columnsManagement {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.MuiDataGrid-columnsManagement::-webkit-scrollbar,
.mobileStepsContent::-webkit-scrollbar {
  display: none;
}
